<template>
	<header class="header">
		<div class="container">
			<div class="header__content">
				<button
					type="button"
					@click="toggleMenu"
					aria-label="Toggle mobile menu"
					aria-labelledby="Toggler"
					class="btn header__mobile-close mobile-menu-toggler"
					:class="{ 'active-menu': activeMenu }"
				>
					<span />
				</button>

				<router-link
					class="header__logo"
					:to="{ name: 'MainRoute', params: { lang: $store.state.uiLanguage } }"
				>
					<img
						src="@/assets/img/svg/logo.svg"
						width="231"
						height="52"
						alt="Логотип"
					/>
				</router-link>

				<transition name="slide-menu">
					<Navigation v-show="activeMenu" class="header__navigation" />
				</transition>
				<transition name="fade" mode="out-in">
					<or-button
						@click="buy"
						class="header__action"
						:height="48"
						v-if="$store.state.isLangFileLoaded"
					>
						<!-- {{ $t('general.buyApartment') }} -->
						{{ $t('general.buyProperty') }}
					</or-button>
					<or-skeleton
						v-else
						style="max-width: 142px; width: 100%; height: 38px"
					/>
				</transition>
			</div>
		</div>
	</header>
</template>

<script setup>
import { watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Navigation from '@/components/Header/components/Navigation.vue'

const route = useRoute()
const activeMenu = ref(false)
const store = useStore()

function buy() {
	store.commit('showModal', {
		name: 'CreateRequestBuy'
	})
}
function toggleMenu() {
	if (activeMenu.value) {
		closeMenu()
	} else {
		openMenu()
	}
}

function openMenu() {
	activeMenu.value = true
	document.body.classList.add('stop-scrolling')
}

function closeMenu() {
	activeMenu.value = false
	document.body.classList.remove('stop-scrolling')
}

watch(() => route.fullPath, closeMenu)
</script>

<style lang="scss" scoped>
.header {
	.container {
		overflow: unset;
	}
	@media screen and (max-width: 768px) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	&__content {
		display: flex;
		align-items: center;
		padding: 30px 0;
		grid-gap: 10px;
	}

	&__mobile-close {
		display: none;
	}

	&__logo {
		display: flex;
		align-items: center;

		img {
			width: 231px;
			height: 52px;
		}
	}

	&__action {
		margin-left: 30px;
	}

	@media (max-width: 1180px) {
		&__action {
			margin-left: 0;
		}
	}

	@media (max-width: 913px) {
		&__action.button {
			padding: 12px 10px;
		}
	}

	@media (max-width: 991px) {
		position: relative;

		.container {
			padding: 0 10px;
		}

		&__content {
			justify-content: space-between;
			padding: 10px 0;
		}

		&__logo {
			img {
				width: 141px;
				height: 32px;
			}
		}

		&__mobile-close {
			display: flex;
		}

		&__navigation {
			position: fixed;
			top: 68px;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: white;
			z-index: 9999;
		}
	}

	@media (min-width: 992px) {
		&__navigation {
			display: flex !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.mobile-menu-toggler {
	width: 24px;
	height: 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span,
	span:before,
	span:after {
		display: block;
		background-color: #000000;
		position: absolute;
		height: 2px;
		width: 24px;
		transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
		border-radius: 2px;
	}

	span {
		&:before {
			content: '';
			margin-top: -8px;
		}

		&:after {
			content: '';
			margin-top: 8px;
		}
	}

	&.active-menu {
		span {
			background: rgba(255, 255, 255, 0);

			&:before {
				margin-top: 0px;
				transform: rotate(405deg);
			}

			&:after {
				margin-top: 0px;
				transform: rotate(-405deg);
			}
		}
	}
}
</style>

<style lang="scss">
.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

// Animation
.slide-menu-enter-active {
	transition: all 0.2s linear;
}

.slide-menu-leave-active {
	transition: all 0.2s linear;
}

.slide-menu-enter-from,
.slide-menu-leave-to {
	transform: translateY(-10px);
	opacity: 0;
}
</style>
